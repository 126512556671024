<template>
  <div class="vscroll-holder">
    <div
      class="vscroll-spacer"
      :style="{
        opacity: 0,
        clear: 'both',
        height: topHeight + 'px',
      }"
    ></div>
    <slot :items="visibleItems"></slot>
    <div
      class="vscroll-spacer"
      :style="{
        opacity: 0,
        clear: 'both',
        height: bottomHeight + 'px',
      }"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'VirtualScroll',
  props: {
    items: { type: Array, default: undefined },
    itemHeight: { type: Number, default: undefined },
  },
  data() {
    return {
      topHeight: 0,
      bottomHeight: 0,
      visibleItems: [],
    };
  },
  watch: {
    items(newValue) {
      // rerender table if data changes
      this.updateWindow();
    },
  },
  mounted() {
    window.vscroll = this;
    this._checkScrollPosition = this.checkScrollPosition.bind(this);
    this.checkScrollPosition();
    this.$el.addEventListener('scroll', this._checkScrollPosition);
    this.$el.addEventListener('wheel', this._checkScrollPosition);
  },
  beforeDestroy() {
    this.$el.removeEventListener('scroll', this._checkScrollPosition);
    this.$el.removeEventListener('wheel', this._checkScrollPosition);
  },
  methods: {
    checkScrollPosition(e = {}) {
      var el = this.$el;

      // prevent parent scroll
      if ((el.scrollTop === 0 && e.deltaY < 0) || (Math.abs(el.scrollTop - (el.scrollHeight - el.clientHeight)) <= 1 && e.deltaY > 0)) {
        e.preventDefault();
      }

      this.updateWindow(e);
    },

    updateWindow(e) {
      // wrapper's clientHeight should be fixed (css:height) for proper render at start
      var visibleItemsCount = Math.ceil(this.$el.clientHeight / this.itemHeight);
      var totalScrollHeight = this.items.length * this.itemHeight;

      var scrollTop = this.$el.scrollTop;
      var offset = 7; // preload some elements above and below
      var firstVisibleIndex = Math.floor(scrollTop / this.itemHeight);
      var lastVisibleIndex = firstVisibleIndex + visibleItemsCount;
      var firstCutIndex = Math.max(firstVisibleIndex - offset, 0); // cut from -offset or 0 if at the table top
      var lastCutIndex = lastVisibleIndex + offset;

      this.visibleItems = this.items.slice(firstCutIndex, lastCutIndex); // set cut part of data collection to render on page

      this.topHeight = firstCutIndex * this.itemHeight; // div-spacer above table to save scroll position
      this.bottomHeight = totalScrollHeight - this.visibleItems.length * this.itemHeight - this.topHeight; // div-spacer bellow table

      //console.log("scroll");
    },
  },
};
</script>

<style scoped>
.vscroll {
  overflow: auto;
  overflow-anchor: none;
}
</style>
