var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vscroll-holder"},[_c('div',{staticClass:"vscroll-spacer",style:({
      opacity: 0,
      clear: 'both',
      height: _vm.topHeight + 'px',
    })}),_vm._t("default",null,{"items":_vm.visibleItems}),_c('div',{staticClass:"vscroll-spacer",style:({
      opacity: 0,
      clear: 'both',
      height: _vm.bottomHeight + 'px',
    })})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }