<template>
  <div>
    <SpinnerLoader v-if="byProjectsStatus !== 'success'" :loading="byProjectsStatus" />
    <template v-else-if="byProjectsStatus === 'success' && byProjects">
      <virtual-scroll :items="byProjects[selectedTab].items" :item-height="28.6" class="vscroll table-responsive mb-0 by-projects">
        <template slot-scope="{ items }">
          <table class="table table-sm small table-hover table-bordered ws-nowrap sticky-headers sticky-footer">
            <thead>
              <tr>
                <th rowspan="2">
                  <div class="resizable agency">{{ $t('table.agency') }}</div>
                </th>
                <th rowspan="2">
                  <div class="resizable project">{{ $t('table.project') }}</div>
                </th>
                <th rowspan="2">
                  <div class="resizable advertiser">{{ $t('table.advertiser') }}</div>
                </th>
                <th rowspan="2">
                  <div class="resizable channel_name">{{ $t('channelModal.channel') }}</div>
                </th>
                <th rowspan="2">
                  <div class="resizable commercial_type">{{ $t('table.commercialType') }}</div>
                </th>
                <th rowspan="2">
                  <div class="resizable placement_type">{{ $t('reports.conditionType') }}</div>
                </th>
                <th rowspan="2" style="white-space: break-spaces">{{ $t('reports.approvedBudget') }}</th>
                <th rowspan="2" style="white-space: break-spaces">{{ $t('reports.calendarPlan') }} ({{ $t('reports.cp') }})</th>
                <th rowspan="2" style="white-space: break-spaces">{{ $t('table.fact') }}+{{ $t('table.plan') }} ({{ $t('reports.current') }})</th>
                <th rowspan="2" style="white-space: break-spaces">{{ $t('reports.difference') }} ({{ $t('reports.current') }}-{{ $t('reports.cp') }})</th>

                <th v-for="(month, index) in months" :key="index" colspan="2">{{ yearInReport }} {{ month }}</th>
              </tr>
              <tr>
                <template v-for="month in months">
                  <th :key="month + '_1'" class="text-right">{{ $t('reports.cp') }}</th>
                  <th :key="month + '_2'" class="text-right">{{ $t('reports.current') }}</th>
                </template>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in items"
                :key="`${item.advertiser_name}_${item.project_name}_${item.channel_name}_${item.placement_type_name}_${item.commercial_type_name}`"
              >
                <td name="agency">{{ item.agency_name }}</td>
                <td name="project">{{ item.project_name }}</td>
                <td name="advertiser">{{ item.advertiser_name }}</td>
                <td name="channel_name">{{ item.channel_name }}</td>
                <td name="commercial_type">{{ item.commercial_type_name }}</td>
                <td name="placement_type">{{ item.placement_type_name }}</td>
                <td class="text-right">{{ item.approved_budget | toFixedAndSpace }}</td>
                <td class="text-right">{{ item.calendar_plan | toFixedAndSpace }}</td>
                <td class="text-right">{{ item.plan_fact | toFixedAndSpace }}</td>
                <td class="text-right">{{ item.difference | toFixedAndSpace }}</td>

                <template v-for="m in item.months">
                  <td :key="m.month + '_1'" class="text-right">{{ m.cp | toFixedAndSpace }}</td>
                  <td :key="m.month + '_2'" class="text-right">{{ m.plan_fact | toFixedAndSpace }}</td>
                </template>
              </tr>
            </tbody>

            <tfoot v-if="byProjects[selectedTab].totals" class="font-weight-bold">
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right">{{ byProjects[selectedTab].totals.approved_budget | toFixedAndSpace }}</td>
                <td class="text-right">{{ byProjects[selectedTab].totals.calendar_plan | toFixedAndSpace }}</td>
                <td class="text-right">{{ byProjects[selectedTab].totals.plan_fact | toFixedAndSpace }}</td>
                <td class="text-right">{{ byProjects[selectedTab].totals.difference | toFixedAndSpace }}</td>

                <template v-for="m in byProjects[selectedTab].totals.months">
                  <td :key="m.month + '_1'" class="text-right">{{ m.cp | toFixedAndSpace }}</td>
                  <td :key="m.month + '_2'" class="text-right">{{ m.plan_fact | toFixedAndSpace }}</td>
                </template>
              </tr>
            </tfoot>
          </table>
        </template>
      </virtual-scroll>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpinnerLoader from '@/components/SpinnerLoader';
import toFixedAndSpace from '@/filters/toFixedAndSpace';
import VirtualScroll from '@/components/VirtualScroll';

export default {
  name: 'ByProjectsTable',
  components: { SpinnerLoader, VirtualScroll },
  filters: { toFixedAndSpace },
  props: {
    selected_year: { type: [String, Object], default: undefined },
  },
  data() {
    return {
      selectedTab: 'All',
    };
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      byProjects: 'getReportByProjects',
      byProjectsStatus: 'getReportByProjectsStatus',
    }),
    months() {
      return [
        this.$i18n.t('month.jan'),
        this.$i18n.t('month.feb'),
        this.$i18n.t('month.mar'),
        this.$i18n.t('month.apr'),
        this.$i18n.t('month.may'),
        this.$i18n.t('month.jun'),
        this.$i18n.t('month.jul'),
        this.$i18n.t('month.aug'),
        this.$i18n.t('month.sep'),
        this.$i18n.t('month.oct'),
        this.$i18n.t('month.nov'),
        this.$i18n.t('month.dec'),
      ];
    },
    yearInReport() {
      if (this.selected_year) return this.selected_year.id;
      else return '';
    },
  },
  destroyed() {
    this.$store.commit('clearReportByProjects');
  },
  methods: {},
};
</script>

<style lang="sass">
.reports-page div.table-responsive.by-projects
  height: calc(100vh - 210px)

.reports-page .by-projects table.table
  div.resizable
    &.channel_name,
    &.commercial_type,
    &.project,
    &.agency,
    &.advertiser,
    &.commercial_type,
    &.placement_type
      min-width: 46px

  td[name="channel_name"],
  td[name="commercial_type"],
  td[name="project"],
  td[name="agency"],
  td[name="advertiser"],
  td[name="commercial_type"],
  td[name="placement_type"]
    max-width: 46px

@media (min-width: 2544px)
  .reports-page div.table-responsive.by-projects
    height: calc(100vh - 154px)
</style>
